<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('menu') }}</h2>
      <tree-view
        v-if="type"
        ref="tree"
        :id="'menu' + type"
        :path="'menu'"
        :labelField="'title'"
        :extraParams="extraParams"
        :actions="actions"
        :add-route="'AddMenu'"
        :add-route-params="addLinkParams"
        :update-route="'UpdateMenu'"
        :add-text="$t('addMenu')"
      />
    </div>
  </div>
</template>

<script>

  import TreeView from '../../components/TreeView';
  import GridViewTable from '../../components/GridViewTable';
  import {actions} from "./tree-actions/menu";

  export default {
    name: 'MenusList',
    components: {
      GridViewTable,
      TreeView,
    },
    props: ['type'],
    watch: {
      type: {
        immediate: true,
        deep: true,
        handler() {
          setTimeout(() => {
            this.$refs.tree.loadItems();
          }, 300)
        }
      }
    },
    data: () => {
      return {
        actions,
      }
    },
    computed: {
      addLinkParams: function () {
        return {type: this.type}
      },
      extraParams: function () {
        return '&filter=type||eq||' + this.type
      }
    },
  }
</script>
